
.signup-page-outer .container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.signup-page-outer .logo {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.reg-inner {
  box-shadow: 10px 10px 34px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: #fff;
  padding: 50px 50px 26px 50px;
}

.reg-inner h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #35234B;
  margin: 0;
}

.reg-boxes {
  margin-top: 30px;
}

.reg_inner_box {
  background: #FFFFFF;
  box-shadow: 7px 7px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  margin-bottom: 24px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  cursor: pointer;
}

.reg_inner_box:hover {
  background: #35234B;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.reg_icon {
  background: #FFFFFF;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  width: 68px;
  height: 68px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg_box-content {
  width: 100%;
  padding-left: 24px;
}

.reg_box-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #000;
  margin: 0;
  min-height: 112px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.reg_inner_box:hover .reg_box-content p {
  color: #fff;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.signup-page-outer .logo a img {
  width: 150px;
}

.signup-page-outer {
  background: url(../img/bg.png) no-repeat scroll center center / cover;
  background-attachment: fixed;
  padding-bottom: 30px;
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.copyright p {
  margin: 0;
}

.reg_social {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social_signin_btn {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #0B070F;
  width: 100%;
  max-width: 371px;
  margin-bottom: 15px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
}

.social_signin_btn:hover {
  color: #0B070F;
}

.social_signin_btn img {
  margin-right: 20px;
}

.social_signin_btn.fb {
  background: #3B5998;
  color: #fff;
}

.social_signin_btn.fb:hover {
  color: #fff;
}

.or {
  margin: 5px 0;
}

.reg_input {
  width: 100%;
  margin-bottom: 20px;
}

.reg_input.check_value label {
  color: #222;
}

.reg_input .MuiFormControl-root {
  width: 100%;
}

.reg_input.reg_select {
  padding-top: 0px;
}

.reg_input.reg_select .MuiFormControl-root {
  margin: 0;
}

.reg_box_bordered {
  width: 100%;
  padding: 20px;
  border: 1px solid #D7D3DB;
  border-radius: 6px;
  margin-bottom: 20px;
}

/* .signup-page-outer .MuiGrid-root {
    width: 100%;
} */

.login-outer .MuiBox-root {
  margin-top: 0;
}
/* .login-outer .MuiBox-root.css-1yyhlhe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */
.login-outer .MuiBox-root.css-vuuwxc {
  display: flex;
  align-items: center;
}

.login-outer {
  background: url(../img/bg.png) no-repeat scroll center center / cover;
}

.login-logo {
  width: 80px;
}
.reg_form .MuiSelect-select{
  padding: inherit !important;
}

.reg_input .MuiInputLabel-root.MuiInputLabel-formControl,
.reg_lable {
  color: #344767 !important;
}
h2.h2-heading {
  margin-bottom: 40px;
}

label.MuiInputLabel-root.reg_lable {
  color: #000 !important;
  font-size: 16px;
}

.check-hint {
  margin-top: 11px;
  font-size: 12px;
  color: #645E6A;
}

.mb-20 {
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #D7D3DB;
  margin: 20px 0;
}

.mt-15 {
  margin-top: 15px;
}


/* .signup-page-outer .MuiGrid-root{
  margin: 0px;
} */
.signup-page-outer .MuiGrid-root.css-1auz1p1-MuiGrid-root {
  max-width: 100%;
  flex-basis: 100%;
}
.signup-page-outer .css-1yyhlhe {
  margin-top: 0px;
}
.signup-page-outer .MuiGrid-root.signup-page-outer2 {
  padding-left: 0px;
  padding-top: 0px;
}
.forgot-password label {
  color: rgba(0, 0, 0, 0.6) !important;
}

  .reg_input label {
    font-size: 14px;
    color: #000;
}
.pd-row {
  width: 100%;
  border-top: 1px solid #dfdfdf;
}

.pd-box {
  width: 100%;
  padding: 10px 0;
}
/* .pd-boxerror {
  width: 100%;
  padding: 10px 0;
} */
.pd-box p {
  font-size: 14px;
  font-weight: 400;
}

.pd-row.last {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}

.pd-box p span {
  min-width: 120px;
  display: inline-block;
  font-weight: 500;
}

.pd-box p span {
  min-width: 120px;
  display: inline-block;
  font-weight: 500;
}

.added-property-images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.added-property-images span {
  width: 24.2%;
  flex: 0 0 auto;
  position: relative;
  height: 180px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #dfdfdf;
}

.added-property-images span img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.added-property-images span:nth-child(4n+4) {
  margin-right: 0;
}

.delete-img {
  position: absolute;
  z-index: 1;
  color: #fff;
  background: #d31b1b;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  right: 10px;
  top: 10px;
}















.signup-page-outer .container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.signup-page-outer .logo {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}

.reg-inner {
  box-shadow: 10px 10px 34px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: #fff;
  padding: 50px 50px 26px 50px;
}

.reg-inner h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #35234B;
  margin: 0;
}

.reg-boxes {
  margin-top: 30px;
}

.reg_inner_box {
  background: #FFFFFF;
  box-shadow: 7px 7px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  margin-bottom: 24px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  cursor: pointer;
}

.reg_inner_box:hover {
  background: #35234B;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.reg_icon {
  background: #FFFFFF;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  width: 68px;
  height: 68px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg_box-content {
  width: 100%;
  padding-left: 24px;
}

.reg_box-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #000;
  margin: 0;
  min-height: 112px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.reg_inner_box:hover .reg_box-content p {
  color: #fff;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.signup-page-outer .logo a img {
  width: 150px;
}

.signup-page-outer {
  background: url(../img/bg.png) no-repeat scroll center center / cover;
  background-attachment: fixed;
  padding-bottom: 30px;
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

.copyright p {
  margin: 0;
}

.reg_social {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.social_signin_btn {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #0B070F;
  width: 100%;
  max-width: 371px;
  margin-bottom: 15px;
  padding: 14px 20px;
}

.social_signin_btn:hover {
  color: #0B070F;
}

.social_signin_btn img {
  margin-right: 20px;
}

.social_signin_btn.fb {
  background: #3B5998;
  color: #fff;
}

.social_signin_btn.fb:hover {
  color: #fff;
}

.or {
  margin: 5px 0 20px 0;
}

.reg_input {
  width: 100%;
  margin-bottom: 20px;
}

.reg_input.check_value label {
  color: #222;
  margin-top: 10px;
}

.reg_input .MuiFormControl-root {
  width: 100%;
}

.reg_input.reg_select {
  padding-top: 0px;
}

.reg_input.reg_select .MuiFormControl-root {
  margin: 0;
}

.reg_box_bordered {
  width: 100%;
  padding: 20px;
  border: 1px solid #D7D3DB;
  border-radius: 6px;
  margin-bottom: 20px;
}

/* .signup-page-outer .MuiGrid-root {
    width: 100%;
} */

.login-outer .MuiBox-root {
  margin-top: 0;
}
/* .login-outer .MuiBox-root.css-1yyhlhe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */
.login-outer .MuiBox-root.css-vuuwxc {
  display: flex;
  align-items: center;
}

.login-outer {
  background: url(../img/bg.png) no-repeat scroll center center / cover;
}

.login-logo {
  width: 80px;
}
.reg_form .MuiSelect-select{
  padding: inherit !important;
}

.reg_input .MuiInputLabel-root.MuiInputLabel-formControl,
.reg_lable {
  color: #344767 !important;
}
h2.h2-heading {
  margin-bottom: 40px;
}

label.MuiInputLabel-root.reg_lable {
  color: #000 !important;
  font-size: 16px;
}

.check-hint {
  margin-top: 11px;
  font-size: 12px;
  color: #645E6A;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background: #D7D3DB;
  margin: 20px 0;
}

.mt-15 {
  margin-top: 15px;
}

.center {
  text-align: center;
}

.h2-heading.with-tagline {
  margin-bottom: 10px;
}

.h2-heading.with-tagline {
  margin-bottom: 10px;
}

.ba-box {
  background: #FFFFFF;
  box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ba-box span {
  background: #FFFFFF;
  border-radius: 6px;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ba-box h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #D84C73;
  text-align: center;
}

.MuiGrid-root.MuiGrid-container.css-skvbsg {
  display: inline-block;
}
.signup-page-outer .MuiGrid-root .css-1etbfcs {
  flex-basis: 100%;
  max-width: 100%;
}

.login-outer .MuiGrid-root.MuiGrid-container.css-skvbsg {
  display: flex;
}

.reg_submit button {
  margin: 0 5px;
}


.ba-box {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  cursor: pointer;
}

.ba-box span {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #fff;
}

.ba-box h3 {
  color: #D84C73;
  font-weight: 400;
  font-size: 15px;
  line-height: 31px;
}

.center {
  text-align: center;
}

.MuiBox-root.css-vuuwxc {
  width: 100%;
}

/* .reg_input.reg_select .MuiOutlinedInput-root.MuiInputBase-root {
  padding: 12px 0;
} */

.referral_form_inner {
  width: 100%;
}

.ref_form_box {
  width: 100%;
  margin-bottom: 30px;
}

.ref_form_box h3 {
  width: 100%;
  font-weight: normal;
  color: #222;
  font-size: 22px;
  margin-bottom: 10px;
}
.referral_form_inner h2 {
  margin-bottom: 10px;
}

.notes-table {
  width: 100%;
  overflow-x: auto;
}

.notes-table table {
  width: 100%;
  border-collapse: collapse;
}

.notes-table table tr th {
  border: 1px solid #dfdfdf;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.notes-table table tr td {
  border: 1px solid #dfdfdf;
  font-size: 14px;
  padding: 10px;
}

.edit-contact-inner {
  width: 100%;
}

.edit-contact-inner h3,.notes h3 {
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

.notes {
  width: 100%;
  margin-top: 20px;
}

.appointment_edit {
  width: 100%;
}

.appointment_edit h4 {
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.reg-inner.no-shadow {
  box-shadow: none;
}

.dashboard-appointment .ba-box h3 {
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
}

.reg_icon span img {
  max-width: 100%;
  width: 40px;
}
.reg_icon span img {
  max-width: 100%;
  width: 40px;
}

.reg_social button {
  width: 100%;
  max-width: 371px;
  border-radius: 12px !important;
  overflow: hidden;
  padding: 13px 16px !important;
  font-size: 18px !important;
  line-height: 31px !important;
  height: 70px;
  margin-bottom: 20px;
}

.reg_social span {
  width: 100%;
  text-align: center;
}
a, a:link, a:visited {
  cursor: pointer;
}
.dashboard-appointment .reg-inner{
  padding: 20px 20px 26px 20px;
}
.css-1lhqsmn-MuiPaper-root-MuiAppBar-root .MuiToolbar-root{
  padding: 0.25rem 0rem !important;
}
.css-aim41u{
  padding: 0px 24px 24px 24px !important;
}
.css-1lhqsmn-MuiPaper-root-MuiAppBar-root{
  padding-top: 0px !important;
}
.css-168isbf-MuiPaper-root-MuiAppBar-root{
  top: 0px !important;
}


.errorTC{
  color:#D84C73;
  font-size:11px;
  display: none;
}

.rdt_TableRow .rdt_TableCell .btn-danger{
  background: rgb(233, 30, 99);
  border: 0px;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
}
.rdt_TableRow .rdt_TableCell .btn-primary{
  background: rgb(95, 95, 95);
  border: 0px;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
}


.app-for {
  width: 100%;
}
.app-for .MuiFormGroup-root {
  display: flex;
  align-items: center;
}
.app-for .MuiFormGroup-root .MuiInputLabel-root {
  margin-right: 20px;
}

.h2-heading.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.error {
  font-size: 0.75rem;
  color: #F44335;
}
.app-accordian {
  margin-top: 16px;
  border-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  overflow: hidden;
}
.table-custom .p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 10px;
  font-size: 13px;
}
.table-custom .p-button {
  width: 30px !important; 
  height: 30px;
}
.table-custom .p-datatable .p-datatable-thead > tr > th {
  padding: 5px 10px;
  font-size: 13px;
  background: #fff;
}
.table-custom .p-inputtext {
  padding: 2px 10px;
}
.table-custom .p-button .p-button-icon {
  font-size: 14px;
}
.table-custom .p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
  margin-left: 3px;
}
.p-tooltip .p-tooltip-text {
  font-size: 12px;
  padding: 5px 10px;
}
.reg_input.auto-complete .MuiOutlinedInput-root {
  padding-top: 5px;
  padding-bottom: 5px;
}
.hd-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.hd-icon .h2-heading {
  margin: 0;
}
.signup-page-outer.after-login {
  background: #fff;
  border-radius: 10px;
  padding: 0 30px;
  margin-top: 30px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.table-custom .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: #f6f6f6;
}
.table-custom .p-datatable .p-sortable-column:focus {
  box-shadow: none;
}
.table-custom .p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 13px;
}
.custom-accordian {
  width: 100%;
  margin-bottom: 10px;
}
.custom-accordian {
  width: 100%;
  margin-bottom: 10px;
}
.custom-accordian .MuiAccordion-root {
  box-shadow: none;
  border: 1px solid #dee2e6;
  border-radius: 0 !important;
}
.custom-accordian .MuiAccordion-root .MuiAccordionSummary-content {
  margin: 0;
  font-size: 16px;
}
.color-white {
  color: #fff !important;
}
.mt-2 {
  margin-top: 20px;
}
.google-signin-btn {
  width: 100%;
  padding: 0 20px;
  margin: 15px 0 0 0;
}

.google-signin-btn {
  width: 100%;
  padding: 0 20px;
  margin: 15px 0 0 0;
}

.google-signin-btn button {
  width: 100%;
}

.appointment-for {
  width: 50%;
  float: left;
}

.app-btn {
  width: 50%;
  float: left;
}

.reg_step {
  width: 100%;
  display: inline-block;
}
.servicesError{
  display: none;
  color: red;
  font-size: 12px;
}
.Addressset{
  display: none;
  font-size: 14px;
}

.eyeplace{
  position: absolute;
  right: 15px;
  top: 6px; 
}
.eyeplacepass{
  display: none;
  position: absolute;
  right: 15px;
  top: 6px;
}

.eyeplaceConf{
  position: absolute;
  right: 15px;
  top: 6px;
}

.eyeplacepassConf{
  display: none;
  position: absolute;
  right: 15px;
  top: 6px; 
}

.appointment-for.special {
  position: absolute;
  top: 16px;
  right: 60px;
}

.str-slam {
  width: 100%;
  padding-left: 24px;
}

.appointment-for p {
  font-size: 1rem;
  text-align: right;
}

.appointment-for p span {
  font-weight: 700;
}

#SelectPatient {
  margin-left: 5px;
  margin-top: 5px;
}

.bar-icn {
  margin-right: 10px !important;
  margin-top: 2px !important;
}

.bar-icn span.material-icons-round {
  font-size: 27px !important;
}

.color-red {
  color: #F44335;
}

.reg_input.label_inline {
  display: flex;
  align-items: center;
}

.reg_input.label_inline label.MuiInputLabel-root {
  margin-right: 20px;
}

.reg_input.check_value.small span.MuiFormControlLabel-label {
  font-weight: normal;
  font-size: 13px;
}

.p-dropdown.p-component.p-inputwrapper {
  align-items: center;
}

.reg_input.with-icon {
  position: relative;
}

.reg_input.with-icon i {
  position: absolute;
  right: 10px;
  top: 5px;
}

.imp-notes {
  width: 100%;
  padding-top: 5px;
}
.EyeIcon {
  position: absolute;
  top: 14px;
  right: 5px;
  z-index: 999;
}
.EyeIcon-outer {
  position: relative;
}
.EyeIcon-outer .hidetextLogin{
  position: absolute;
  right: 3px;
  top: 5px;
  display: none;
}
.EyeIcon-outer .showtextLogin{
  position: absolute;
  right: 3px;
  top: 5px;
  display: block;
}

.special-appointment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.special-appointment .MuiFormGroup-root {
  align-items: center;
}

.special-appointment .MuiFormGroup-root label.MuiInputLabel-root {
  margin-right: 20px;
}

.relative {
  position: relative;
}

.email-error {
  color: #ff0404;
  font-size: 13px;
}

.lg-alert {
  padding: 0 6px;
  margin-top: 5px;
}

.lg-alert .MuiAlert-message {padding: 8px 0;font-size: 13px;font-weight: 500;}

.login-input input {
  background-image: none !important;
  background: #fff;
}
.hidetext{
  display: none;
}
.DynamicColor {
  color: #fff !important;
}

.mt-20 {
  margin-top: 20px;
}

.reg_social button {
  width: 100%;
  max-width: 371px;
  border-radius: 12px !important;
  overflow: hidden;
  padding: 13px 16px !important;
  font-size: 18px !important;
  line-height: 31px !important;
  height: 70px;
  margin-bottom: 20px;
  text-transform: none;
  font-weight: 500;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.fb-login {
  width: 371px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.fb-login svg {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 30px;
  height: 30px;
}

.reg_social .fb-login button {
  margin: 0;
}

.logo-outer {
  width: 100%;
  padding-left: 18px;
  padding-right: 10px;
}

.logo-wrap img {
  width: 57px;
}

.logo-inner {
  width: 100% !important;
  text-align: left;
}

.logo-text {
  font-size: 22px !important;
  font-weight: 500 !important;
  white-space: nowrap;
}

.address-alert p {
  width: 100%;
  font-size: 13px;
}

.address-alert p span {
  display: inline-block !important;
  font-size: 13px;
}

/* .alt-title {
  font-size: 13px !important;
} */

.alt-title {
  font-size: 13px !important;
  margin-bottom: 0 !important;
}
.hideDiv{
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.fontsize{
  font-size: 14px !important;
}

.notes-table td.nt-date {width: 130px;}

.notes-table  td.nt-action {
    width: 83px;
}

.NotesTableDynamic .p-datatable-tbody tr td:nth-child(1) {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px;
}

.NotesTableDynamic .p-datatable-tbody tr td:nth-child(3) {
  width: 83px !important;
  min-width: 83px !important;
  max-width: 83px;
}
.AddNotes {
  width: 30px;
  height: 30px;
  max-width: 30px;
}

.inline-checkbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.inline-checkbox label {
  margin-top: 0 !important;
}

.arrageCheckBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.arrageCheckBox .MuiFormControlLabel-root {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  margin-right: 0;
  padding-right: 15px;
}